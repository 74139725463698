<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="flex flex-wrap justify-between items-center w-90 center pv4-l pv3">
                <div class="w-100">
                    <div>
                        <router-link :to="{ name: 'Reports' }" class="font14 f53 b" style="color: #132c8c">
                            &lt; Report</router-link
                        >
                        <div class="pv4 f3 b">Sales Inventory Reports</div>
                    </div>
                    <div class="flex mbWrap justify-between w-100 gap8 pb3" style="align-items: flex-end">
                        <div class="w-30 mbPadd2">
                            <label class="block f500 font14 co3rLabel" for="report-to">From</label>
                            <input
                                type="date"
                                name="startDate"
                                v-model="state.startDate"
                                class="trialDate w-100"
                                id="report-to"
                            />
                        </div>
                        <div class="w-30 mbPadd2">
                            <label class="block f500 font14 co3rLabel" for="report-from">To</label>
                            <input type="date" name="endDate" v-model="state.endDate" class="trialDate w-100" id="report-from" />
                        </div>

                        <select name="comparison" id="filter" v-model="state.filter" class="w-30 trialDate mbPadd">
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Annually">Annually</option>
                        </select>

                        <div class="w-10">
                            <button class="filter card-boxx" title="Refresh" @click="handleInventoryRecords">
                                <!-- filter_list.svg -->
                                <!-- <img :src="require('@/assets/images/Reload.svg')" alt="filter icon" /> -->
                                <span style="color: #1a2035">Generate</span>
                            </button>
                        </div>
                    </div>

                    <!-- <div class="reportFilter w-100">

                        <button class="filter card-boxx" title="Refresh" @click="handleInventoryRecords">

                            <img :src="require('@/assets/images/Reload.svg')" alt="filter icon" />
                        </button>
                        <div class="filter mr3 ml3" title="Refresh">
                            <div class="tc flex items-center">
                                <div><img src="@/assets/images/filter_list.svg" alt="filter icon" /></div>
                                <div class="pl2 tl">Filter</div>
                            </div>
                        </div>

                        <button class="flex items-center pv2">
                            <span><img :src="require('@/assets/images/search3.svg')" /></span>
                            <span class="pl2 tl">Search</span>
                        </button>
                    </div> -->
                    <div class="margin24">
                        <div class="overflow-auto no-wrap" style="height: 500px">
                            <table class="f6 w-100" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th class="py-8 invPad manageHeader pl2 fw6 tl p9 pr3">Product Name</th>
                                        <th class="py-8 invPad manageHeader pl3 fw6 tc p9 pr3">Quantity</th>
                                        <th class="py-8 invPad manageHeader pl3 fw6 tr p9 pr3">Total Sales Amount</th>
                                    </tr>
                                </thead>
                                <tbody class="lh-copy">
                                    <tr v-for="data in report" :key="data._id.product">
                                        <td class="pv2 pl2 pr3">{{ data._id.productName }}</td>
                                        <td class="pv2 pl3 pr3 tc">
                                            {{ formatQuantity(data.count) }}
                                        </td>
                                        <td class="pv2 pl3 pr3 tr">
                                            {{ formatAmount(data.total, $store.state.Settings?.currency) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="reportFilter mt4">
                        <button
                            style="padding: 10px 20px"
                            class="dropdown-togglex"
                            role="button"
                            id="dropdownMenuLink"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <div class="flex items-center">
                                <span style="margin-right: 10px">Export</span>
                                <span><img :src="require('@/assets/images/white-caret.svg')" /></span>
                            </div>
                        </button>
                        <ul class="dropdown-menu mbDrop" aria-labelledby="dropdownMenuLink" style="cursor: pointer">
                            <li @click="downloadFile">Download as <span class="b">CSV</span> File</li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import AppWrapper from '@/layout/AppWrapper'
import OrgData from '@/components/OrgData'
import { computed, onMounted, reactive } from 'vue'
import { formatDateInLocal, formatDateFilter, downloadCSV, formatAmount, formatDate, formatQuantity, getMonday } from '@/utils/lib'
import { useStore } from 'vuex'

export default {
    name: 'InventoryReportNew',
    components: { AppWrapper, OrgData },

    setup() {
        const store = useStore()
        const orgData = computed(() => store.state.Auth.userData)
        const report = computed(() => store.state.Reporting.salesInventoryReportList)
        const state = reactive({
            currentDate: '',
            startDate: '',
            endDate: '',
            filter: 'Daily',
        })

        const month = new Date().getMonth()
        const year = new Date().getFullYear()
        const day = new Date().getDate()

        const tableHead = {
            monthly: 'Month',
            daily: 'Day',
            weekly: 'Week',
            annually: 'Year',
        }

        const handleInventoryRecords = () => {
            let startDate = new Date()
            let endDate = new Date()

            state.currentDate = new Date(year, month, day)
            if (state.filter.toLowerCase() === 'daily') {
                startDate = new Date(year, month, day)
                endDate = new Date(year, month, day)
                state.startDate = formatDateFilter(startDate)
                state.endDate = formatDateFilter(endDate)
            } else if (state.filter.toLowerCase() === 'weekly') {
                // 1st week of the Year to current week. Week starts from Monday//
                startDate = getMonday(new Date())
                endDate = new Date(year, month, day)
                state.startDate = formatDateFilter(startDate)
                state.endDate = formatDateFilter(endDate)
            } else if (state.filter.toLowerCase() === 'monthly') {
                // Monthly: Jan of the current year - Current month
                startDate = new Date(year, month, 1)
                endDate = new Date(year, month, day)
                state.startDate = formatDateFilter(startDate)
                state.endDate = formatDateFilter(endDate)
            } else if (state.filter.toLowerCase() === 'annually') {
              // 2021 to current Year
                startDate = new Date(2021, 0, 1)
                endDate = new Date(year, month, day)
                state.startDate = formatDateFilter(startDate)
                state.endDate = formatDateFilter(endDate)
            }
            if (state.startDate > state.endDate) {
                return store.dispatch('Alert/setAlert', {
                    message: 'Start date cannot be greater than end date',
                    status: false,
                })
            }

            if (!state.filter) {
                return store.dispatch('Alert/setAlert', {
                    message: 'Filter is not selected',
                    status: false,
                })
            }

            // console.log(state.startDate, state.endDate)

            store.dispatch('Reporting/getSalesInventoryReportList', {
                currentDate: state.currentDate,
                startDate: startDate,
                endDate: endDate,
                filter: state.filter,
            })
        }

        onMounted(() => {
            const currentDate = new Date(year, month, day)
            // const startDate = new Date(year, month, 1)
            const startDate = new Date(year, month, day)
            const endDate = new Date(year, month, day)
            const filter = 'Daily'

            const query = {
                currentDate: currentDate,
                startDate: startDate,
                endDate: endDate,
                filter: filter,
            }

            state.startDate = formatDateFilter(startDate)
            state.endDate = formatDateFilter(endDate)

            store.dispatch('Reporting/getSalesInventoryReportList', query)
        })

        const downloadFile = () => {
            downloadCSV(report.value?.data || [])
        }

        return {
            orgData,
            report,
            formatDateInLocal,
            tableHead,
            state,
            handleInventoryRecords,
            formatDateFilter,
            downloadFile,
            formatAmount,
            formatQuantity,
            formatDate,
        }
    },
}
</script>
<style>
.invPad {
    padding: 10px;
    font-weight: bold;
}
.margin24 {
    margin-top: 24px;
}
@media screen and (max-width: 467px) {
    .mbPadd {
        width: 100%;
        margin-top: 16px;
    }
    .mbPadd2 {
        width: 48%;
        margin-top: 16px;
    }
    .mbWrap {
        flex-wrap: wrap;
        width: 100%;
    }
}
</style>
